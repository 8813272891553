<template>
    <div 
        ref="meetingContainer"
        class="meetings-iframe-container" />
</template>

<script>
export default {
    data() {
        return {};
    },
    mounted() {
        this.$refs.meetingContainer.dataset.src = 'https://go.dealersunited.com/meetings/adam323?embed=true';

        this.scriptElem = document.createElement('script');
        this.scriptElem.setAttribute('src', 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js');
        document.head.appendChild(this.scriptElem);
    },
    beforeDestroy() {
        this.scriptElem.remove();
    }
};
</script>